import React, { useMemo } from "react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import tw from "twin.macro"

import {
  BaseMDXProvider,
  Footer,
  MainNav,
  Container,
  H1,
  H3,
  Overline,
  PrimaryContent,
  SecondaryContent,
  Seo,
  Caption,
  Tagline,
} from "../components"
import {
  cssMq,
  useDefaultBreakpointValue as useBreakpointValue,
} from "../components/mediaquery"
import { MDXRenderer } from "gatsby-plugin-mdx"

export default function EditorsNotePageTemplate({
  data: {
    mdx: { frontmatter, body: children },
    allFile,
  },
}) {
  const images = useMemo(
    () =>
      allFile.nodes
        .map(node => node?.childImageSharp?.fluid)
        .filter(src => !!src),
    []
  )
  const leadImageSrc = images.filter(src => src.originalName === frontmatter.leadImage)[0] // prettier-ignore
  const seoImage = useMemo(() => images.filter(src => src.originalName === frontmatter.socialRefers.image)[0], []) // prettier-ignore
  const leadImageAspectRatio = useBreakpointValue([
    null,
    null,
    16 / 9,
    16 / 9,
    16 / 9,
  ])
  const storiesImageAspRatio = useBreakpointValue([null, null, 1.5, 1.5, 1.5])

  return (
    <>
      <Seo
        title={frontmatter.contentType}
        description={frontmatter.heading}
        image={seoImage ?? leadImageSrc}
      />
      <div className="lg:mb-6">
        <MainNav className="sticky top-0 z-10 bg-white" />
        <Container className="mt-6 lg:mt-10">
          {leadImageSrc && (
            <SecondaryContent
              as={Image}
              css={{ borderRadius: "16px" }}
              className="mb-6 lg:mb-10"
              fluid={{
                ...leadImageSrc,
                aspectRatio: leadImageAspectRatio ?? leadImageSrc.aspectRatio,
              }}
            />
          )}
          <PrimaryContent
            as={Tagline}
            tw="border-t border-b border-solid border-black"
          >
            <span className="font-bold">{frontmatter.topicArea}</span>
            &nbsp;/&nbsp;{frontmatter.contentType}
          </PrimaryContent>
          <SecondaryContent
            as={H1}
            tw="lg:col-span-10 lg:col-start-2 text-center py-8 md:py-10 lg:py-14 text-av-gray-6"
          >
            {frontmatter.heading}
          </SecondaryContent>
          <PrimaryContent className="hidden lg:block border-t border-black" />
        </Container>
      </div>
      <BaseMDXProvider socialRefers={frontmatter.socialRefers}>
        {frontmatter.date && (
          <Container className="mb-6">
            <PrimaryContent as={Caption}>{frontmatter.date}</PrimaryContent>
          </Container>
        )}
        <MDXRenderer>{children}</MDXRenderer>
      </BaseMDXProvider>
      {frontmatter.blockStories && (
        <Container tw="lg:mb-20 lg:block">
          <div
            className="lg:flex justify-center border-black border-t lg:border-t-0 pt-6 lg:pt-0 w-full mx-auto"
            css={cssMq({
              maxWidth: ["100%", "520px", "560px", "100%", "100%"],
            })}
          >
            {frontmatter.blockStories?.map(story => {
              const imageSrc = images.filter(src => src.originalName === story.image)[0] // prettier-ignore
              return (
                <Link
                  to={story.url}
                  key={`${story.url}_${story.topicArea}`}
                  className="flex flex-row-reverse lg:block lg:w-1/3 pb-6 lg:pb-0 mb-6 lg:mb-0 border-b border-black lg:mx-3"
                >
                  {imageSrc && (
                    <Image
                      className="w-1/3 lg:w-full lg:mb-10"
                      fluid={{
                        ...imageSrc,
                        aspectRatio:
                          storiesImageAspRatio || imageSrc?.aspectRatio,
                      }}
                      css={cssMq({
                        borderRadius: ["8px", "8px", "8px", "16px", "16px"],
                      })}
                    />
                  )}
                  <div className="lg:pb-10 w-2/3 lg:w-full text-left lg:text-center">
                    <Overline tw="hidden md:block lg:text-xs mb-6 lg:mb-10">
                      <span className="text-av-purple font-bold">
                        {story.topicArea}
                      </span>
                      &nbsp;/&nbsp;
                      {story.contentType}
                    </Overline>
                    <H3
                      className="mb-6 lg:mb-10 font-serif text-av-gray-6"
                      css={cssMq({
                        fontSize: [
                          "1.25rem",
                          "1.25rem",
                          "1.75rem",
                          "2.25rem",
                          "2.25rem",
                        ],
                        lineHeight: [1.4, 1.4, 1.23, 1.11, 1.11],
                      })}
                    >
                      {story.headline}
                    </H3>
                    <Caption>By {story.author}</Caption>
                  </div>
                </Link>
              )
            })}
          </div>
        </Container>
      )}
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query EditorsNotesContentQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        leadImage
        heading
        topicArea
        contentType
        date
        blockStories {
          image
          topicArea
          contentType
          headline
          author
          url
        }
        socialRefers {
          title
          notes
          shareText
          image
        }
      }
    }
    allFile(filter: { relativeDirectory: { eq: "contents" } }) {
      nodes {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
    }
  }
`
